import React from 'react'
import './cardelek.scss';
import { ek1, ek10, ek11, ek12, ek13, ek14, ek15, ek16, ek17, ek18, ek19, ek2, ek20, ek3, ek4, ek5, ek6, ek7, ek8, ek9 } from './import';
import { t } from 'i18next';



const Card = React.forwardRef((props, ref) => {
    // const { t } = useTranslation()
    return (
      <div ref={ref}>
          <div className='alt-baslik'>
            {t('ekipmanlar')}
        <hr />
          </div>
         <div className='ekipman'>

            <div>
              <img src={ek1} alt="ekipman" />
              <p>{t('asimetrikcatal')}</p>
            </div>
            <div>
              <img src={ek2} alt="ekipman" />
              <p>{t('simetrikcatal')}</p>
            </div>
            <div>
              <img src={ek3} alt="ekipman" />
              <p>{t('elekalti')}</p>
            </div>
            <div>
              <img src={ek4} alt="ekipman" />
              <p>{t('kromboru')}</p>
            </div>
            <div>
              <img src={ek5} alt="ekipman" />
              <p>{t('elekaltiboru')}</p>
            </div>
            <div>
              <img src={ek6} alt="ekipman" />
              <p>{t('kromzaviye')}</p>
            </div>
            <div>
              <img src={ek7} alt="ekipman" />
              <p>{t('kromzaviye')}</p>
            </div>
            <div>
              <img src={ek8} alt="ekipman" />
              <p>{t('akisborusu')}</p>
            </div>
            <div>
              <img src={ek9} alt="ekipman" />
              <p>{t('reduksiyon')}</p>
            </div>
            <div>
              <img src={ek10} alt="ekipman" />
              <p>{t('catalboru')}</p>
            </div>
            <div>
              <img src={ek11} alt="ekipman" />
              <p>{t('nefeslik')}</p>
            </div>
            <div>
              <img src={ek12} alt="ekipman" />
              <p>{t('kelepce')}</p>
            </div>
            <div>
              <img src={ek13} alt="ekipman" />
              <p>{t('fircalitapeten')}</p>
            </div>
            <div>
              <img src={ek14} alt="ekipman" />
              <p>{t('damlatapeten')}</p>
            </div>
            <div>
              <img src={ek15} alt="ekipman" />
              <p>{t('dipsiyirici')}</p>
            </div>
            <div>
              <img src={ek16} alt="ekipman" />
              <p>{t('tapeten')}</p>
            </div>
            <div>
              <img src={ek17} alt="ekipman" />
              <p>{t('ytapeten')}</p>
            </div>
            <div>
              <img src={ek18} alt="ekipman" />
              <p>{t('ydamla')}</p>
            </div>
            <div>
              <img src={ek19} alt="ekipman" />
              <p>{t('elekfitili')}</p>
            </div>
            <div>
              <img src={ek20} alt="ekipman" />
              <p>{t('torba')}</p>
            </div>

         </div>
          </div>
    )
}
)
export default Card;

// return (
  
//     <div>
//         {data[cardIndex].map(item => (
//     <div className='card'>
//       <div className='alt-baslik'>
     
//        {item.title}
//       </div>
//       <div className='urun-altdetay'>
//       <div className='alt-resim'>
//            <img src={item.Image} alt="resim" />
//       </div>
//       <div className='alt-metin'>
//           <p>{item.aciklama}</p>
//       </div>

//       </div>

//     </div>
//         ))}
//   </div>
// )