import React, {useState} from 'react';
import logo from '../../assets/logo.png';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.scss';
import '../../index.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import { katalog } from '../import';


const Navbar= () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const { t } = useTranslation()
  
  
  
    return (
      <div className='navbar'>
        <div className='navbar-links'>
          <a href="/">
          <div className='navbar-links_logo'>
          <img src={logo} alt="millger-logo" />
          </div>
          </a>
         
          <div className='navbar-links_container'>
            <ul>
              <li>
                <Link to="/Kurumsal">{t('kurumsal')}</Link>
              </li>
              <li>
                <Link to="/Urunler">{t('urunler')}</Link>
              </li>
              <li>
                <Link to="/KasaElek">{t('elekkasa')}</Link>
              </li>
              <li>
              {/* <a href="assets/katalog.pdf">{t('katalog')}</a>  */}
             {/*  <Link to="/katalog.pdf" onClick={(event) => { event.preventDefault(); window.open("assets/katalog.pdf"); }}>{t('katalog')}</Link> */}
             <Link to="/Katalog">{t('katalog')}</Link>
             </li>
              <li>
                <Link to="/Contact">{t('iletisim')}</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='navbar_menu'>
          {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
          }
          {toggleMenu && (
            <div className='navbar_menu_container scale-up-center'>
              <div className='navbar_menu_container-links'>
              <ul>
              <li>
                <Link to="/Kurumsal">{t('kurumsal')}</Link>
              </li>
              <li>
                <Link to="/Urunler">{t('urunler')}</Link>
              </li>
              <li>
                <Link to="/KasaElek">{t('elekkasa')}</Link>
              </li>
              <li>
             {/*  <Link to="/katalog.pdf" onClick={(event) => { event.preventDefault(); window.open("assets/katalog.pdf"); }}>{t('katalog')}</Link> */}
             <Link to="/Katalog">{t('katalog')}</Link> 
             </li>
              <li>
                <Link to="/Contact">{t('iletisim')}</Link>
              </li>
            </ul>
              </div>
  
             </div>
          )}
          
        </div>
  
      </div>
     
    )
    
  }
  
  export default Navbar;