import React from 'react'
import './card.scss';



const Card = React.forwardRef(({ title, resim, resim1, resim2, resim3, resim4 }, ref) => (
    // const { t } = useTranslation()
    
    <div ref={ref}>
        <div>
           <div className='card'>
          <div className='alt-baslik'>
            {title}
        <hr />
          </div>
          <div className='urun-altdetay2'>
          <div className='alt-resim'>
               <img src={resim} alt="resim" />
          </div>
          </div>
          <div className='alt-resimler'>
            <div className='yatay'>
            <div id='alt-resim'> <img src={resim1} alt="resim" /></div>
            <div id='alt-resim'> <img src={resim2} alt="resim" /></div>

            </div>
            <div className='yatay'>

            <div id='alt-resim'> <img src={resim3} alt="resim" /></div>
            <div id='alt-resim'> <img src={resim4} alt="resim" /></div>
            </div>
            
          </div>
        </div>
            </div>
            </div>
    
  ));

export default Card;

// return (
  
//     <div>
//         {data[cardIndex].map(item => (
//     <div className='card'>
//       <div className='alt-baslik'>
     
//        {item.title}
//       </div>
//       <div className='urun-altdetay'>
//       <div className='alt-resim'>
//            <img src={item.Image} alt="resim" />
//       </div>
//       <div className='alt-metin'>
//           <p>{item.aciklama}</p>
//       </div>

//       </div>

//     </div>
//         ))}
//   </div>
// )