import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './temizleme.scss';
import { Link } from 'react-router-dom';
import { bugdaypacal, tahilpaketleme, undolum, unpaketleme, ventildolum, yempaketleme } from './import.js';
import CardUrun  from './CardUrun';
import UrunMenu from './urunMenu.jsx';

const urunler = [
    { id: "Un Paketleme", titleKey: 'unpaketlememakinasi', image: unpaketleme, aciklamaKey: 'unpaketlememakinasiaciklama' },
    { id: "Un Dolum", titleKey: 'undolummakinasi', image: undolum, aciklamaKey: 'undolummakinasiaciklama' },
    { id: "Yem Paketleme Makinası", titleKey: 'yempaketlememakinasi', image: yempaketleme, aciklamaKey: 'yempaketlememakinasiaciklama' },
    { id: "Tahıl Paketleme Makinası", titleKey: 'tahilpaketlememakinasi', image: tahilpaketleme, aciklamaKey: 'tahilpaketlememakinasiaciklama' },
    { id: "Ventil Dolum Makinası", titleKey: 'venildolummakinasi', image: ventildolum, aciklamaKey: 'venildolummakinasiaciklama' },
    { id: "Buğday Paçal Sistemi", titleKey: 'bugdaypacalsistemi', image: bugdaypacal, aciklamaKey: 'bugdaypacalsistemiaciklama' },
];
function Paketleme() {
    const { t } = useTranslation()
    const [active, setActive] = useState("");
    const refs = useRef(urunler.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
      }, {}));
    
      useEffect(() => {
        if (active && refs.current[active]) {
          refs.current[active].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, [active]);

      
  return (
    <div className='anatemiz'>
    <UrunMenu />
    <div>
      {urunler.map((urun) => (
        active === urun.id && (
          <CardUrun
            ref={refs.current[urun.id]}
            key={urun.id}
            title={t(urun.titleKey)}
            image={urun.image}
            aciklama={t(urun.aciklamaKey)}
          />
        )
      ))}
    </div>
    <section className='temizleme'>
      <div id='tem'>
        <p id='tem-baslik'>{t('paketleme')}</p>
        <p id='tem-baslik'>{t('slogan')}</p>
        <hr />
      </div>
      <div id='tem-yatay'>
        {urunler.map((urun) => (
          <Link key={urun.id} onClick={() => setActive(urun.id)} className='tem-urun' to="">
            <p className='buton'>
              {t(urun.titleKey)}
              <hr className='button-hr'/>
            </p>
           
            <div id='tem-urun'>
              <img src={urun.image} alt={t(urun.titleKey)} />
            </div>
          </Link>
        ))}
      </div>
    </section>
  </div>
  )
}

export default Paketleme
