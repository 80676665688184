import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "swiper/scss";
import "swiper/scss/navigation";
import { Carkasa } from '../urunler/import';
import './kasaelek.scss';
import {ahsap,ahsapahsap,kasatip,kasacizim,plastik,plastik1,plastik2,plastik3,plastik4,ahal1,ahal2,ahal3,ahal4,ahsap1,ahsap2,ahsap3,ahsap4, ek1 } from './import';
import { Link } from 'react-router-dom';
import Card from './Card';
import CardCasa from './CardCasa'
import CardTip from './CardTip';
import CardEkipman from './CardEkipman';

function KasaElek() {
  const { t } = useTranslation()
  const [active, setActive] = useState("");
  const refs = useRef({
    "Plastik Kasa": React.createRef(),
    "Ahsap Alu": React.createRef(),
    "Ahsap Ahsap": React.createRef(),
    "Kasa Cizim": React.createRef(),
    "Kasa Tip": React.createRef(),
    "Ekipman": React.createRef(),
  });

  useEffect(() => {
    if (active && refs.current[active]) {
      refs.current[active].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [active]);

  return (
    <div className='elekkasa'>
<div className='carousel'>
        <Carkasa/>
      </div>
            <div className='grid'>
          <sectiona className='sag1 section'>
            <div className='kasakasa'>
          <Link onClick={() => setActive("Plastik Kasa")} to="">
          <div className='kasa'>
            <div className='kasatext'>{t('plastik')}</div>
            <div className='kasaresim'><img src={plastik} alt="elekkasa" /></div>
            <button>{t('keşfet')}</button>
          </div>
          </Link>
          <Link onClick={() => setActive("Ahsap Alu")} to="">
          <div className='kasa'>
            <div className='kasatext'>{t('ahsapaluminyum')}</div>
            <div className='kasaresim'><img src={ahsap} alt="elekkasa" /></div>
            <button>{t('keşfet')}</button>
          </div>
          </Link>

          <Link onClick={() => setActive("Ahsap Ahsap")} to="">
          <div className='kasa'>
            <div className='kasatext'>{t('ahsapahsap')}</div>
            <div className='kasaresim'><img src={ahsapahsap} alt="elekkasa" /></div>
            <button>{t('keşfet')}</button>
          </div>
          </Link>
            </div>
            <div className='kasakasa'>
          <Link onClick={() => setActive("Kasa Tip")} to="">
          <div className='kasa'>
            <div className='kasatext'>{t('kasatip')}</div>
            <div className='kasaresim'><img src={kasatip} alt="elekkasa" /></div>
            <button>{t('keşfet')}</button>
          </div>
          </Link>
          <Link onClick={() => setActive("Kasa Cizim")} to="">
          <div className='kasa'>
            <div className='kasatext'>{t('kasacizim')}</div>
            <div className='kasaresim'><img src={kasacizim} alt="elekkasa" /></div>
            <button>{t('keşfet')}</button>
          </div>
          </Link>
          <Link onClick={() => setActive("Ekipman")} to="">
          <div className='kasa'>
            <div className='kasatext'>{t('ekipmanlar')}</div>
            <div className='kasaresim'><img src={ek1} alt="elekkasa" /></div>
            <button>{t('keşfet')}</button>
          </div>
          </Link>
            </div>
          </sectiona>
      </div>
      <div>
      {active === "Plastik Kasa" && (
        <Card
          ref={refs.current["Plastik Kasa"]}
          title={t('plastik')}
          resim={plastik}
          resim1={plastik1}
          resim2={plastik2}
          resim3={plastik3}
          resim4={plastik4}
        />
      )}
      {active === "Ahsap Alu" && (
        <Card
          ref={refs.current["Ahsap Alu"]}
          title={t('ahsapaluminyum')}
          resim={ahsap}
          resim1={ahal1}
          resim2={ahal2}
          resim3={ahal3}
          resim4={ahal4}
        />
      )}
      {active === "Ahsap Ahsap" && (
        <Card
          ref={refs.current["Ahsap Ahsap"]}
          title={t('ahsapahsap')}
          resim={ahsapahsap}
          resim1={ahsap1}
          resim2={ahsap2}
          resim3={ahsap3}
          resim4={ahsap4}
        />
      )}
      {active === "Kasa Cizim" && (
        <CardCasa
          ref={refs.current["Kasa Cizim"]}
          title={t('kasacizim')}
        />
      )}
      {active === "Kasa Tip" && (
        <CardTip
          ref={refs.current["Kasa Tip"]}
          title={t('kasatip')}
        />
      )}
      {active === "Ekipman" && (
        <CardEkipman
          ref={refs.current["Ekipman"]}
          title={t('ekipman')}
        />
      )}
    </div>
    <div className='seruven'> </div>
  </div>
  )
}

export default KasaElek