import React from 'react'
import {e1,e2,e3,e4,e5,e6,e7,e8,e9,e10,e11} from './import';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';




const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const items = [
      <div className="item" data-value="8">
      <img src={e1} alt="elek" />
    </div>,
  <div className="item" data-value="1">
      <img src={e2} alt="elek" />
    </div>,
    <div className="item" data-value="2">
      <img src={e3} alt="elek" />
    </div>,
    <div className="item" data-value="3">
      <img src={e4} alt="elek" />
    </div>,
    <div className="item" data-value="4">
      <img src={e5} alt="elek" />
    </div>,
    <div className="item" data-value="5">
      <img src={e6} alt="elek" />
    </div>,
      <div className="item" data-value="6">
      <img src={e7} alt="elek" />
    </div>,
      <div className="item" data-value="7">
      <img src={e8} alt="elek" />
    </div>,
      <div className="item" data-value="7">
      <img src={e9} alt="elek" />
    </div>,
      <div className="item" data-value="7">
      <img src={e10} alt="elek" />
    </div>,
      <div className="item" data-value="7">
      <img src={e11} alt="elek" />
    </div>,
  ];

const Carousel = () => {
  return (
    <div>
 <section className="p-basic">
			<AliceCarousel 
      mouseTracking items={items} 
      responsive={responsive} 
      controlsStrategy="alternate"
      touchTracking
      keyboardNavigation
      disableDotsControls />
			 
		</section>
        </div>
  )
}

export default Carousel