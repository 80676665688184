import React from 'react';
// import { useTranslation } from 'react-i18next';
//import PdfViewerComponent from '../../components/PdfViewerComponent';

function Katalog() {
  
  return (
    <div className='PDF-Viewer'>
    <div className='elekkasa'>
    <iframe
      src="assets/katalogde.pdf"
      width="100%"
      height="900px"
      title="Katalog"
    />
     
    </div>
       {/*  <PdfViewerComponent document ={'katalog.pdf'}></PdfViewerComponent> */}
        {/* <a href="katalog.pdf">{t('katalog')}</a> */}
    </div>
  );
}
export default Katalog;
