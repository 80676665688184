import React from 'react'
import './card.scss';
import { Navigation } from "swiper";
import {case1,case10,case11,case13,case12,case2, case3, case4, case5, case6, case7, case8, case9} from './import';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import { useTranslation } from 'react-i18next';



const Card = React.forwardRef((props, ref) => {
    const { t } = useTranslation()
    return (
      <div ref={ref}>
        <div className='alt-baslik'>
            {t('kasacizim')}
        <hr />
          </div>
      <Swiper
      navigation={true}
      modules={[Navigation]}
      className="swiper-slide"
      slidesPerView={1}
      autoPlay={true}
      scrollbar={{ draggable: true }}

      >
        <SwiperSlide><img src={case1} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case2} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case3} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case4} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case5} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case6} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case7} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case8} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case9} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case10} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case11} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case12} alt="fabrika" /></SwiperSlide>
        <SwiperSlide><img src={case13} alt="fabrika" /></SwiperSlide>

         </Swiper>
          </div>
    
    )
});

export default Card;

// return (
  
//     <div>
//         {data[cardIndex].map(item => (
//     <div className='card'>
//       <div className='alt-baslik'>
     
//        {item.title}
//       </div>
//       <div className='urun-altdetay'>
//       <div className='alt-resim'>
//            <img src={item.Image} alt="resim" />
//       </div>
//       <div className='alt-metin'>
//           <p>{item.aciklama}</p>
//       </div>

//       </div>

//     </div>
//         ))}
//   </div>
// )