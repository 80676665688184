import React from 'react'
import { Navigation } from "swiper";
import {d1,d2,d3,d4,d5} from './import';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "./urunler.scss";

function Carousel() {
  return (
    <div>
    <Swiper
    navigation={true}
    modules={[Navigation]}
    className="swiper-slide"
    autoPlay={true}
    >
      <SwiperSlide><img src={d1} alt="fabrika" /></SwiperSlide>
      <SwiperSlide><img src={d2} alt="fabrika" /></SwiperSlide>
      <SwiperSlide><img src={d3} alt="fabrika" /></SwiperSlide>
      <SwiperSlide><img src={d4} alt="fabrika" /></SwiperSlide>
      <SwiperSlide><img src={d5} alt="fabrika" /></SwiperSlide>
       </Swiper>
        </div>
  )
}

export default Carousel