import React from 'react'
import { useTranslation } from 'react-i18next';
import './kurumsal.scss';


function Kurumsal() {

  const { t } = useTranslation()

  return (
    <div className='main'>
      <div className='sec1'>
        <div className='sec1-1'> 
        <p>{t('sec1')}</p>
          </div>
        <div className='sec1-2'> 
         </div>
      </div>
      <div className='sec2'> 
       </div>
      <div className='sec3'>
        <div className='slogan1'> <p>{t('kurumsal-slogan')}</p></div>
        <div className='text1'> <p>{t('sec3')}</p></div>
        <div className='sec5'> </div>
        <div className='text1'> <p>{t('sec4')}</p></div>
      </div>
      <div className='sec4'></div>
    </div>
  )
}

export default Kurumsal