import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {elek,kirici,filtre,havakilidi, vals, yuksekkapke, flakevals, flakekurutucu, pisirmekulesi, vibrokepekfircasi, irmikkirici, detasor} from './import.js';
import './temizleme.scss';
import CardUrun from './CardUrun';
import ElekCarousel from './ElekCarousel';
import UrunMenu from './urunMenu.jsx';


const urunler = [
  { id: "KareElek", titleKey: 'kareelek', image: elek, aciklamaKey: 'kareelekaciklama' },
  { id: "Kirici", titleKey: 'kirici', image: kirici, aciklamaKey: 'kiriciaciklama' },
  { id: "JetFiltre", titleKey: 'filtre', image: filtre, aciklamaKey: 'jetfiltreaciklama' },
  { id: "HavaKilidi", titleKey: 'havakilidi', image: havakilidi, aciklamaKey: 'havakilidiaciklama' },
  { id: "Vals", titleKey: 'vals', image: vals, aciklamaKey: 'valsaciklama' },
  { id: "YüksekKapasiteKontrolEleği", titleKey: 'yuksekkapasitelikontrolelegi', image: yuksekkapke, aciklamaKey: 'yuksekkapasitelikontrolelegiaciklama' },
  { id: "FlakeValsi", titleKey: 'flakevalsi', image: flakevals, aciklamaKey: 'flakevalsiaciklama' },
  { id: "FlakeÜrünKurutucu", titleKey: 'flakeurunkurutucu', image: flakekurutucu, aciklamaKey: 'flakeurunkurutucuaciklama' },
  { id: "FlakePişirmeKulesi", titleKey: 'flakepİsirmekulesi', image: pisirmekulesi, aciklamaKey: 'flakpisirmekulesiaciklama' },
  { id: "VibroKepekFırçaso", titleKey: 'vibrokepekfircasi', image: vibrokepekfircasi, aciklamaKey: 'vibrokepekfircasiaciklama' },
  { id: "İrmik Kırıcı", titleKey: 'irmikkirici', image: irmikkirici, aciklamaKey: 'irmikkiriciaciklama' },
  { id: "Detanşör", titleKey: 'detansor', image: detasor, aciklamaKey: 'detansoraciklama' },
];
function Ogutme() {
  const { t } = useTranslation()
  const [active, setActive] = useState("");
  const refs = useRef(urunler.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {}));

  useEffect(() => {
    if (active && refs.current[active]) {
      refs.current[active].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [active]);

  return (
    <div className='anatemiz'>
    <UrunMenu />
    <div>
      {urunler.map((urun) => (
        active === urun.id && (
          <CardUrun
            ref={refs.current[urun.id]}
            key={urun.id}
            title={t(urun.titleKey)}
            image={urun.image}
            aciklama={t(urun.aciklamaKey)}
          />
        )
      ))}
    </div>
    <section className='temizleme'>
      <div id='tem'>
        <p id='tem-baslik'>{t('ogutme')}</p>
        <p id='tem-baslik'>{t('slogan')}</p>
        <hr />
      </div>
      <div id='tem-yatay'>
        {urunler.map((urun) => (
          <Link key={urun.id} onClick={() => setActive(urun.id)} className='tem-urun' to="">
            <p className='buton'>
              {t(urun.titleKey)}
              <hr className='button-hr'/>
            </p>
           
            <div id='tem-urun'>
              <img src={urun.image} alt={t(urun.titleKey)} />
            </div>
          </Link>
        ))}
      </div>
    </section>
    <ElekCarousel/> 
  </div>
    /* <div className='anatemiz'>
    <UrunMenu/>
  <div>
        {active === "Kare Elek" && <CardUrun  title={t('kareelek')} image={elek} aciklama={t('kareelekaciklama')} />}
        {active === "Kirici" && <CardUrun title={t('kirici')} image={kirici} aciklama={t('kiriciaciklama')}/>}
        {active === "Jet Filtre" && <CardUrun  title={t('filtre')} image={filtre} aciklama={t('radyaltararaciklama')}/>}
        {active === "Hava Kilidi" && <CardUrun  title={t('havakilidi')}image={havakilidi} aciklama={t('havakilidiaciklama')}/>}
     
       </div>
    <section className='temizleme'>
     <div id='tem'>
       <p id='tem-baslik'>{t('ogutme')}</p>
       <p id='tem-baslik'>{t('slogan')}</p>
        </div>
     <div id='tem-yatay'>
           <Link onClick={() => setActive("Kare Elek")} className='tem-urun' to=""> 
           <p className='buton'>
             {t('kareelek')}
             </p>
         <div id='tem-urun'>
           <img src={elek} alt="öğütme teknolojileri" />
         </div>
           </Link>
           <Link onClick={() => setActive("Kirici")} className='tem-urun' to=""> 
           <p className='buton'>
             {t('kirici')}
             </p>
         <div id='tem-urun'>
           <img src={kirici} alt="öğütme teknolojileri" />
         </div>
           </Link>
           </div>
           <div id='tem-yatay'>
           <Link onClick={() => setActive("Jet Filtre")} className='tem-urun' to=""> 
           <p className='buton'>
             {t('filtre')}
             </p>
         <div id='tem-urun'>
           <img src={filtre} alt="öğütme teknolojileri" />
         </div>
           </Link>
           <Link onClick={() => setActive("Hava Kilidi")} className='tem-urun' to=""> 
           <p className='buton'>
             {t('havakilidi')}
             </p>
         <div id='tem-urun'>
           <img src={havakilidi} alt="öğütme teknolojileri" />
         </div>
           </Link>
        
         
                 
     </div>
    </section>
     <ElekCarousel/> 
 </div> */
  )
}

export default Ogutme