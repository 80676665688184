import React from 'react'
import './urunler.scss'
// import "swiper/scss";
// import "swiper/scss/navigation";
import { useTranslation } from 'react-i18next';
import { Carousel } from './import';
import {bugday,bugday2,depolama,paketleme} from './import'
import { Link } from 'react-router-dom';
// import YoutubeEmbed from "./YoutubeEmbed";
// import '../../components/header/style.scss';
import ReactPlayer from 'react-player'
import UrunMenu from './urunMenu';



function Urunler() {
  const { t } = useTranslation()
  return (
    <div className='urunler'>
          {/* <YoutubeEmbed embedId="dLUKIERWz_Q" /> */}
          <ReactPlayer 
          playing='true'
          muted='true'
          className='react-player'
          url='https://www.youtube.com/watch?v=dLUKIERWz_Q'
          width='100%'
          height='100vh'
        />
         <div id='urunler'>
         <UrunMenu/>
         <div className='urunalt'></div>
         </div>
      {/* <div className='urun-slo'>
         <h3> {t('urun')}</h3>
         <h3> {t('slogan')}</h3>
         </div>
      <div className='banner'> </div> */}
   
       
      <div className='carousel'>
        <Carousel/>
      </div>

    </div>
  )
}

export default Urunler