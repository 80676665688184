import React from "react";
import "./footer.scss";
import { desmud } from "../../components/import.js";
import { ImPhone } from "react-icons/im";
import { HiMail, HiOutlineLocationMarker } from "react-icons/hi";
import { Link } from "react-router-dom";
import { FaFacebook, FaLinkedin } from "react-icons/fa";

function Footer() {
  return (
    <div className="footer">
      <div className="adres">
        <div className="bol1">
          <div className="logo"> </div>
        </div>
        <div className="bol2">
          <div className="millger">
           {/*  <div className="adres2">
              <div className="adres1">
                <div className="icons">
                  <HiOutlineLocationMarker />{" "}
                </div>
                <div>
                  <a href="https://www.google.com/maps/dir//M%C4%B0LLGER+KARE+ELEK+S%C4%B0STEMLER%C4%B0+M%C4%B0MAR+S%C4%B0NAN+MAHALLES%C4%B0+ANKARA+YOLU+5.CAD+D:NO:+16+19100+MERKEZ%2F%C3%87orum/@40.5169566,34.9187836,18z/data=!4m5!4m4!1m0!1m2!1m1!1s0x4080d5b59eea7843:0xa01a23a2651d136b">
                    <p>
                      Ankara Yolu 5.Cad No:16 Çorum Sanayi Sitesi <br /> Çorum /
                      TÜRKİYE
                    </p>
                  </a>
                </div>
              </div>
              <div className="adres1">
                <div className="icons">
                  <ImPhone />{" "}
                </div>
                <div>
                  <p> +90 364 234 40 02 - 03</p>
                </div>
              </div>
              <div className="adres1">
                <div className="icons">
                  <HiMail />{" "}
                </div>
                <div className="mail">
                  <a href="mailto:info@millger.com">info@millger.com</a>
                </div>
              </div>
            </div> */}
            <div className="adres2">
              <div className="adres1">
                <div className="icons">
                  <HiOutlineLocationMarker />{" "}
                </div>
                <div>
                  <a href="https://www.google.com/maps/place/Neuerostra%C3%9Fe+16/@52.2090033,8.3504319,336m/data=!3m1!1e3!4m7!3m6!1s0x47ba1d6c76aec32b:0xb0063b518ee8f879!4b1!8m2!3d52.209532!4d8.35087!16s%2Fg%2F11c5mq8lch?hl=tr&entry=ttu">
                    <p>
                    Neuerostr. 16 49324 <br /> Melle GERMANY
                    </p>
                  </a>
                </div>
              </div>
              <div className="adres1">
                <div className="icons">
                  <ImPhone />{" "}
                </div>
                <div>
                  <p> +49 160 4941836</p>
                </div>
              </div>
              <div className="adres1">
                <div className="icons">
                  <HiMail />{" "}
                </div>
                <div className="mail">
                  <a href="mailto:info@millger.com">info@millger.de</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sag">
        <div className="social">
          <a href="https://www.facebook.com/Millger-Plansifter-Systems-948997381850317/">
            <FaFacebook />
          </a>
          <a href="https://tr.linkedin.com/in/millgerplansiftersystems">
            <FaLinkedin />
          </a>
        </div>
        <div className="desmud">
          <img src={desmud} alt="desmud" />{" "}
        </div>
        <div className="derux">
          <div> </div>
          <Link to="/Kvkk">
            Kişisel Verileri Koruma Kanunu Aydınlatma Metni
          </Link>
          <br />
          <a href="http://www.derux.com.tr/">
            © 2022 Millger. Powered by DERUX. All right reserved
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
