import React from 'react'
import './cardurun.scss';

const Card = React.forwardRef(({ title, image, aciklama }, ref) => (
    <div ref={ref}>
      <div className='card2'>
        <div className='alt-baslik'>
          {title}
          <hr />
        </div>
        <div className='urun__altdetay'>
          <div className='alt-resim'>
            <img src={image} alt="resim" />
          </div>
          <div className='alt-metin'>
            <p>{aciklama}</p>
          </div>
        </div>
      </div>
    </div>
  ));
/* 

    const Card = ({ title,image,aciklama}) => {
    // const { t } = useTranslation()
    return (
      
        <div>
           <div className='card2'>
          <div className='alt-baslik'>
            {title}
        <hr />
          </div>
          <div className='urun__altdetay'>
          <div className='alt-resim'>
               <img src={image} alt="resim" />
          </div>
          <div className='alt-metin'>
              <p>{aciklama}</p>
          </div>
          </div>
        </div>
            </div>
    )
}
 */
export default Card;

// return (
  
//     <div>
//         {data[cardIndex].map(item => (
//     <div className='card'>
//       <div className='alt-baslik'>
     
//        {item.title}
//       </div>
//       <div className='urun-altdetay'>
//       <div className='alt-resim'>
//            <img src={item.Image} alt="resim" />
//       </div>
//       <div className='alt-metin'>
//           <p>{item.aciklama}</p>
//       </div>

//       </div>

//     </div>
//         ))}
//   </div>
// )