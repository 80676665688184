import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {copsasoru,cebritav,radyal,havakanali, vibrosasor, tasayirici, simoncop, kabuksoyucu, triyor, cekiclidegirmen, yuvarlakcekicli, tozaspiratoru, pnomatikaspirator, dikyikama, yoguntav, borumiknatis} from './import.js';
import './temizleme.scss';
import CardUrun from './CardUrun';
import UrunMenu from './urunMenu.jsx';
// import Data from './Data.js';


const urunler = [
  { id: "Çöp Sasörü", titleKey: 'copsasoru', image: copsasoru, aciklamaKey: 'copsasoruaciklama' },
  { id: "VibroSasor", titleKey: 'vibrocopsasoru', image: vibrosasor, aciklamaKey: 'vibrocopsasoruaciklama' },
  { id: "SimonÇöpSasörü", titleKey: 'simoncopsasoru', image: simoncop, aciklamaKey: 'simoncopsasoruaciklama' },
  { id: "CebriTav", titleKey: 'acilicebritav', image: cebritav, aciklamaKey: 'acilicebritavaciklama' },
  { id: "RadyalTarar", titleKey: 'radyaltarar', image: radyal, aciklamaKey: 'radyaltararaciklama' },
  { id: "HavaKanalı", titleKey: 'havakanali', image: havakanali, aciklamaKey: 'havakanaliaciklama' },
  { id: "TaşAyırıcı", titleKey: 'tasayirici', image: tasayirici, aciklamaKey: 'tasayiriciaciklama' },
  { id: "KabukSoyucu", titleKey: 'kabuksoyucu', image: kabuksoyucu, aciklamaKey: 'kabuksoyucuaciklama' },
  { id: "Triyör", titleKey: 'triyor', image: triyor, aciklamaKey: 'triyoraciklama' },
  { id: "ÇekiçliDeğirmen", titleKey: 'cekiclidegirmen', image: cekiclidegirmen, aciklamaKey: 'cekiclidegirmenaciklama' },
  { id: "YuvarlakÇekiçliDeğirmen", titleKey: 'yuvarlakcekiclidegirmen', image: yuvarlakcekicli, aciklamaKey: 'yuvarlakcekiclidegirmenaciklama' },
  { id: "TozAspiratörü", titleKey: 'tozaspiratoru', image: tozaspiratoru, aciklamaKey: 'tozaspiratoruaciklama' },
  { id: "PnömatikAspirator", titleKey: 'pnomatikaspirator', image: pnomatikaspirator, aciklamaKey: 'pnomatikaspiratoraciklama' },
  { id: "YoğunTav", titleKey: 'yoguntav', image: yoguntav, aciklamaKey: 'yoguntavaciklama' },
  { id: "BoruMıknatıs", titleKey: 'borumiknatis', image: borumiknatis, aciklamaKey: 'borumiknatisaciklama' },
  { id: "DikYıkamaMakinası", titleKey: 'dikyikamamakinasi', image: dikyikama, aciklamaKey: 'dikyikamamakinasiaciklama' }
];

function Temizleme() {
  const { t } = useTranslation();
  const [active, setActive] = useState("");
  const refs = useRef(urunler.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {}));

  useEffect(() => {
    if (active && refs.current[active]) {
      refs.current[active].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [active]);

  return (
    <div className='anatemiz'>
      <UrunMenu />
      <div>
        {urunler.map((urun) => (
          active === urun.id && (
            <CardUrun
              ref={refs.current[urun.id]}
              key={urun.id}
              title={t(urun.titleKey)}
              image={urun.image}
              aciklama={t(urun.aciklamaKey)}
            />
          )
        ))}
      </div>
      <section className='temizleme'>
        <div id='tem'>
          <p id='tem-baslik'>{t('temizleme')}</p>
          <p id='tem-baslik'>{t('slogan')}</p>
          <hr />
        </div>
        <div id='tem-yatay'>
          {urunler.map((urun) => (
            <Link key={urun.id} onClick={() => setActive(urun.id)} className='tem-urun' to="">
              <p className='buton'>
                {t(urun.titleKey)}
                <hr className='button-hr'/>
              </p>
             
              <div id='tem-urun'>
                <img src={urun.image} alt={t(urun.titleKey)} />
              </div>
            </Link>
          ))}
        </div>
      </section>
    </div>
  
 /*    <div className='anatemiz'>
       <UrunMenu/>
     <div>
        {active === "Çöp Sasörü" && <CardUrun  title={t('copsasoru')} image={copsasoru} aciklama={t('copsasoruaciklama')}/>}
        {active === "CebriTav" && <CardUrun title={t('acilicebritav')} image={cebritav} aciklama={t('cebritavaciklama')}/>}
        {active === "RadyalTarar" && <CardUrun  title={t('radyaltarar')} image={radyal} aciklama={t('radyaltararaciklama')}/>}
        {active === "HavaKanalı" && <CardUrun  title={t('havakanali')} image={havakanali} aciklama={t('havakanaliaciklama')}/>}
     
       </div>
       <section className='temizleme'>
        <div id='tem'>
          <p id='tem-baslik'>{t('temizleme')}</p>
          <p id='tem-baslik'>{t('slogan')}</p>
           </div>
        <div id='tem-yatay'>
              <Link onClick={() => setActive("Çöp Sasörü")} className='tem-urun' to=""> 
              <p className='buton' >
                {t('copsasoru')}
                </p>
            <div id='tem-urun'>
              <img src={copsasoru} alt="temizleme teknolojileri" />
            </div>
              </Link>
              <Link onClick={() => setActive("CebriTav")}className='tem-urun' to=""> 
              <p className='buton'>
                {t('acilicebritav')}
                </p>
            <div id='tem-urun'>
              <img src={cebritav} alt="temizleme teknolojileri" />
            </div>
              </Link>
              </div>
              <div id='tem-yatay'>
              <Link onClick={() => setActive("RadyalTarar")}className='tem-urun' to=""> 
              <p className='buton'>
                {t('radyaltarar')}
                </p>
            <div id='tem-urun'>
              <img src={radyal} alt="temizleme teknolojileri" />
            </div>
              </Link>
              <Link onClick={() => setActive("HavaKanalı")} className='tem-urun' to=""> 
              <p className='buton'>
                {t('havakanali')}
                </p>
            <div id='tem-urun'>
              <img src={havakanali} alt="temizleme teknolojileri" />
            </div>
              </Link>
        </div>
       </section>
      
    </div> */
  )
}

export default Temizleme