import React from 'react'
import './kvkk.scss'

function Kvkk() {
  return (
    <div className='kvkk'><h3 className='baslik'>
    KİŞİSEL VERİLERİN İŞLENMESİ HAKKINDA AYDINLATMA METNİ  
    </h3>
    <p className='metin'> 
    Kişisel verilerin işlenmesi hakkındaki bu aydınlatma metni, 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında gerçekleştirilecek aydınlatma yükümlülüğü sırasında uyulacak usul ve esasları belirleyen “Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uygulanacak Usul ve Esaslar Hakkında Tebliğ” kapsamında hazırlanmıştır.
    <br />
    <br />
Şirketimiz, 6698 Sayılı Kanun’da tanımlandığı şekli ile “Veri Sorumlusu” sıfatına sahiptir.
<br />
<br />

Şirketimiz ile ilişkili tüm şahıslara ait her türlü kişisel verilerin, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK Kanunu”)’na uygun olarak işlenebilmesine ve saklanılmasına özen göstermekteyiz. Kişisel verileriniz, aşağıdaki kapsamda işlenmektedir.
<br />
<br />

Kişisel Verilerin Hangi Amaçla İşleneceği:
Kişisel verileriniz, ticari faaliyetler, insan kaynakları uygulamaları ve güvenlik önlemleri nedenleriyle elde edilmekte ve saklanmaktadır. Güvenlik amacıyla bina girişlerinde ziyaretçi kaydı alınıp açık ve kapalı alanlar güvenlik kamerası ile izlenmektedir.
<br />
<br />
Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği:
Kişisel verileriniz, Şirketimiz faaliyetleri kapsamında, ilgili mevzuata uygun olarak yurtiçi ve yurtdışı ortaklıklarımıza, tedarikçilere, müşterilere, resmi ya da özel kurum veya kuruluşlara veya sair üçüncü kişilere aktarılabilir.
<br />
<br />
Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi:
Şirketimiz faaliyetlerinin yürütülmesi için kişisel verileriniz sözlü, yazılı ve elektronik ortamlarda otomatik veya otomatik olmayan yöntemlerle toplanmakta ve saklanmaktadır.
<br />
<br />
Kişisel Veri Sahibinin 6698 sayılı Kanun’un 11. maddesinde Sayılan Hakları:
Şirketimize yazılı şekilde başvurarak, 6698 Sayılı Kanun’un 11. maddesi uyarınca;
<br />
Kişisel verilerinizin işlenip işlenmediğini,
<br />
Şayet işlenmişse, buna ilişkin bilgileri, işlenme amacını ve bu amaca uygun kullanılıp kullanılmadığını,<br />
Söz konusu verilerin aktarıldığı yurt içindeki veya yurt dışındaki 3. kişilerin kim/kimler olduğunu,<br />
Kişisel verileriniz eksik ya da yanlış̧ işlenmişse bunların düzeltilmesini,<br />
Kişisel verilerinizin Kanunun 7. maddesinde öngörülen şartlar çerçevesinde silinmesini ya da yok edilmesini ve bu kapsamda Şirketimizce yapılan işlemlerin bilgilerin aktarıldığı üçüncü kişilere bildirilmesini,<br />
Kişisel verilerinizin münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkması halinde buna itiraz etme ve kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde zararın giderilmesini talep edebilirsiniz. <br />
<br />
6698 Sayılı Kanun’da yer aldığı şekilde yukarıda belirtilen haklarınızı 01.01.2020 tarihinden itibaren kullanabilirsiniz.
<br /> 
<br />
Saygılarımızla, Millger®
<br />

    </p></div>
  )
}

export default Kvkk