import React from 'react'
import './urunler.scss'
import { useTranslation } from 'react-i18next';
import {bugday,bugday2,depolama,paketleme} from './import'
import { Link } from 'react-router-dom';
export default function UrunMenu() {
    const { t } = useTranslation()
  return (
    <div>
    <section className='urun-grup section'>
     
    <Link to="/Temizleme">
        <div className='urun1'> 
          <div className='baslik'> 
          <p>{t('temizleme')} </p> 
          <p>{t('slogan')}</p></div>
          <div className='resim'> <img src={bugday2} alt="" /></div>
          <button>{t('keşfet')}</button>
         {/* <button onClick={() => <Carousel/>}>{t('keşfet')}</button> */}
        </div>
        </Link>
      <Link to="/Ogutme">
        <div className='urun1'> 
          <div className='baslik'> <p>{t('ogutme')}</p> <p>{t('slogan')}</p></div>
          <div className='resim'> <img src={bugday} alt="" /></div>
          <button>{t('keşfet')}</button>
        </div>
      </Link>
      <Link to="/Tasima">
        <div className='urun1'> 
          <div className='baslik'> <p>{t('tasima')}</p> <p>{t('slogan')}</p></div>
          <div className='resim'> <img src={depolama} alt="" /></div>
          <button>{t('keşfet')}</button>
        </div>
      </Link>
      <Link to="/Paketleme">
        <div className='urun1'> 
          <div className='baslik'> <p>{t('paketleme')}</p> <p>{t('slogan')}</p></div>
          <div className='resim'> <img src={paketleme} alt="" /></div>
          <button>{t('keşfet')}</button>
        </div>
      </Link>
    </section>
    
    </div>
  )
}
