import React from 'react';
import "./header.scss";
// import {FaFacebook,FaLinkedin} from 'react-icons/fa';
import './style.scss'
// import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next';
// import { logo } from '../import';



function Header() {
  useTranslation()
  return (
    <div className="header">
   {/*      <div className="icon"> 
        <a href="https://www.facebook.com/Millger-Plansifter-Systems-948997381850317/"><FaFacebook/></a>
        <a href="https://tr.linkedin.com/in/millgerplansiftersystems"><FaLinkedin/></a>
        </div> */}
        <div class="back">
        <div class="layer background"></div>
        <div class="layer time-machine"></div> 
        
        <div class="layer foreground"></div>
    </div>
        <div className="">
        
        {/* <div className='thnks'>
          <p>{t('tesekkur')} </p> 
          <ReactPlayer
              playing='true'
              muted='true'
              className='react-player'
              url='https://www.youtube.com/watch?v=dLUKIERWz_Q'
              width='100%'
              height='35%'
            />
      </div> */}
     { /* <div className="elek"> </div> */}
     {/*  <div className='video'>
      <img src={logo}  alt="logo" style={{width:'200px'}} />
      </div> */}
        

      </div>
    </div>
  )
}

export default Header