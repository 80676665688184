import React from 'react'
import './card.scss';
import { Navigation } from "swiper";
import {kasa1, kasa2, kasa3, kasa4, kasa5, kasa6, kasa7, kasa8, kasa9, kasa10, kasa11, kasa12, kasa13, kasa14, kasa15, kasa16, kasa17, kasa18, kasa19, kasa20, kasa21} from './import';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import { useTranslation } from 'react-i18next';



const Card = React.forwardRef((props, ref) => {
    const { t } = useTranslation()
    return (
      <div ref={ref}>
        <div className='alt-baslik'>
            {t('kasatip')}
        <hr />
          </div>
      <Swiper
      navigation={true}
      modules={[Navigation]}
      className="swiper-slide"
      slidesPerView={2}
      autoPlay={true}
      scrollbar={{ draggable: true }}

      >
        <SwiperSlide><img src={kasa1} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa2} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa3} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa4} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa5} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa6} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa7} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa8} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa9} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa10} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa11} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa12} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa13} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa14} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa15} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa16} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa17} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa18} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa19} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa20} alt="sieve" /></SwiperSlide>
        <SwiperSlide><img src={kasa21} alt="sieve" /></SwiperSlide>
   

         </Swiper>
          </div>
    )
})

export default Card;

// return (
  
//     <div>
//         {data[cardIndex].map(item => (
//     <div className='card'>
//       <div className='alt-baslik'>
     
//        {item.title}
//       </div>
//       <div className='urun-altdetay'>
//       <div className='alt-resim'>
//            <img src={item.Image} alt="resim" />
//       </div>
//       <div className='alt-metin'>
//           <p>{item.aciklama}</p>
//       </div>

//       </div>

//     </div>
//         ))}
//   </div>
// )