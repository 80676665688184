import React from 'react'
import { Navigation } from "swiper";
import {c,c1,c2,c3,c4,c5,c6} from './import';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "./urunler.scss";

function Carousel() {
  return (
    <div>
    <Swiper
    navigation={true}
    modules={[Navigation]}
    className="swiper-slide"
    autoPlay={true}
    >
      <SwiperSlide><img src={c} alt="fabrika" /></SwiperSlide>
      <SwiperSlide><img src={c1} alt="fabrika" /></SwiperSlide>
      <SwiperSlide><img src={c2} alt="fabrika" /></SwiperSlide>
      <SwiperSlide><img src={c3} alt="fabrika" /></SwiperSlide>
      <SwiperSlide><img src={c4} alt="fabrika" /></SwiperSlide>
      <SwiperSlide><img src={c5} alt="fabrika" /></SwiperSlide>
      <SwiperSlide><img src={c6} alt="fabrika" /></SwiperSlide>
    </Swiper>
        </div>
  )
}

export default Carousel