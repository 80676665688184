import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {elevator,eklus,helezon,tupvida,silobosaltici,dagitici,kontrol,konveyor, pnomatikyon, aktuatorluyon, unvidatsiyici, ciftlihelezonvida, vibrobesleyici, reduktorluklape, pnomatiksurguluklape, teleskopikbant, cavubant, acilibant, bocekoldurucu, megajet, siloustufiltre, blower, pnomatiksiklon, monosiklon, ipekgerme, karesilo, yuvsilo} from './import.js';
import './temizleme.scss';
import CardUrun from './CardUrun';
import UrunMenu from './urunMenu.jsx';

const urunler = [
  { id: "Elevator", titleKey: 'elevator', image: elevator, aciklamaKey: 'elevatoraciklama' },
  { id: "Eklus", titleKey: 'eklus', image: eklus, aciklamaKey: 'eklusaciklama' },
  { id: "Helezon", titleKey: 'helezon', image: helezon, aciklamaKey: 'helezonvidaaciklama' },
  { id: "Tupvida", titleKey: 'tupvida', image: tupvida, aciklamaKey: 'tupvidaaciklama' },
  { id: "SiloBosaltici", titleKey: 'silobosaltici', image: silobosaltici, aciklamaKey: 'silobosalticiaciklama' },
  { id: "Dağıtıcı", titleKey: 'dagitici', image: dagitici, aciklamaKey: 'dagiticiaciklama' },
  { id: "Konveyor", titleKey: 'konveyor', image: konveyor, aciklamaKey: 'konveyoraciklama' },
  { id: "Kontrol Eleği", titleKey: 'kontrolelegi', image: kontrol, aciklamaKey: 'kontrolelekaciklama' },
  { id: "PnömatikKlape", titleKey: 'pnomatikyonklapesi', image: pnomatikyon, aciklamaKey: 'pnomatikyonklapesiaciklama' },
  { id: "AktüatörlüKlape", titleKey: 'aktuatorluyonklapesi', image: aktuatorluyon, aciklamaKey: 'aktuatorluyonklapesiaciklama' },
  { id: "UnVidaTaşıycı", titleKey: 'unvidatasiyici', image: unvidatsiyici, aciklamaKey: 'unvidatasiyiciaciklama' },
  { id: "ÇiftliHelezon", titleKey: 'ciftlihelezonvida', image: ciftlihelezonvida, aciklamaKey: 'ciftlihelezonvidaaciklama' },
  { id: "VibroBesleyici", titleKey: 'vibrobesleyici', image: vibrobesleyici, aciklamaKey: 'vibrobesleyiciaciklama' },
  { id: "Redüktörlü Sürgülü Klape", titleKey: 'reduktorlusurguluklape', image: reduktorluklape, aciklamaKey: 'reduktorlusurguluklapeaciklama' },
  { id: "Pnömatik Sürgülü Klape", titleKey: 'pnomatiksurguluklape', image: pnomatiksurguluklape, aciklamaKey: 'pnomatiksurguluklapeaciklama' },
  { id: "Teleskopik Bant", titleKey: 'teleskopikbant', image: teleskopikbant, aciklamaKey: 'teleskopikbantaciklama' },
  { id: "Çavuş Bant", titleKey: 'cavusbant', image: cavubant, aciklamaKey: 'cavusbantaciklama' },
  { id: "Açılı Bant", titleKey: 'acilibant', image: acilibant, aciklamaKey: 'acilibantaciklama' },
  { id: "Böcek Öldürücü", titleKey: 'bocekoldurucu', image: bocekoldurucu, aciklamaKey: 'bocekoldurucuaciklama' },
  { id: "Mega Jet Filtre", titleKey: 'megajetfiltre', image: megajet, aciklamaKey: 'megajetfiltreaciklama' },
  { id: "Silo Üzeri Filtre", titleKey: 'silouzerifiltre', image: siloustufiltre, aciklamaKey: 'silouzerifiltreaciklama' },
  { id: "Blower İstasyonu", titleKey: 'bloweristasyonu', image: blower, aciklamaKey: 'bloweristasyonuaciklama' },
  { id: "Pnömatik Siklon", titleKey: 'pnomatiksiklon', image: pnomatiksiklon, aciklamaKey: 'pnomatiksiklonaciklama' },
  { id: "Mono Siklon", titleKey: 'monosiklon', image: monosiklon, aciklamaKey: 'monosiklonaciklama' },
  { id: "İpek Gerdirme", titleKey: 'ipekgerdirme', image: ipekgerme, aciklamaKey: 'ipekgerdirmeaciklama' },
  { id: "Un Silosu", titleKey: 'silo', image: yuvsilo, aciklamaKey: 'siloaciklama' },
  { id: "Kare Silo", titleKey: 'silo', image: karesilo, aciklamaKey: 'siloaciklama' }

];
function Tasima() {
  const { t } = useTranslation()
  const [active, setActive] = useState("");
  const refs = useRef(urunler.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {}));

  useEffect(() => {
    if (active && refs.current[active]) {
      refs.current[active].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [active]);



  return (
    <div className='anatemiz'>
      <UrunMenu />
      <div>
        {urunler.map((urun) => (
          active === urun.id && (
            <CardUrun
              ref={refs.current[urun.id]}
              key={urun.id}
              title={t(urun.titleKey)}
              image={urun.image}
              aciklama={t(urun.aciklamaKey)}
            />
          )
        ))}
      </div>
      <section className='temizleme'>
        <div id='tem'>
          <p id='tem-baslik'>{t('tasima')}</p>
          <p id='tem-baslik'>{t('slogan')}</p>
          <hr />
        </div>
        <div id='tem-yatay'>
          {urunler.map((urun) => (
            <Link key={urun.id} onClick={() => setActive(urun.id)} className='tem-urun' to="">
              <p className='buton'>
                {t(urun.titleKey)}
                <hr className='button-hr'/>
              </p>
             
              <div id='tem-urun'>
                <img src={urun.image} alt={t(urun.titleKey)} />
              </div>
            </Link>
          ))}
        </div>
      </section>
    </div>
  
/*     <div className='anatemiz'>
    <UrunMenu/>
  <div>
  {active === "Elevator" && <CardUrun  title={t('elevator')} image={elevator} aciklama={t('elevatoraciklama')}/>}
  {active === "Eklus" && <CardUrun  title={t('eklus')} image={eklus} aciklama={t('eklusaciklama')}/>}
  {active === "Helezon" && <CardUrun  title={t('helezonvida')} image={helezon} aciklama={t('helezonvidaaciklama')}/>}
  {active === "Tupvida" && <CardUrun  title={t('tupvida')} image={tupvida} aciklama={t('tupvidaaciklama')}/>}
  {active === "SiloBosaltici" && <CardUrun  title={t('silobosaltici')} image={silobosaltici} aciklama={t('silobosalticiaciklama')}/>}
  {active === "Dağıtıcı" && <CardUrun  title={t('dagitici')} image={dagitici} aciklama={t('dagiticiaciklama')}/>}
  {active === "Kontrol Eleği" && <CardUrun  title={t('kontrolelegi')} image={kontrol} aciklama={t('kontrolelekaciklama')} />}
  {active === "Klape" && <CardUrun  title={t('klape')} image={klape} aciklama={t('klapeaciklama')}/>}
  {active === "Konveyor" && <CardUrun  title={t('konveyor')} image={konveyor} aciklama={t('konveyoraciklama')}/>}
  {active === "Un Silosu" && <CardUrun  title={t('silo')} image={silo} aciklama={t('siloaciklama')}/>}
  </div>
    <section className='temizleme'>
     <div id='tem'>
       <p id='tem-baslik'>{t('tasima')}</p>
       <p id='tem-baslik'>{t('slogan')}</p>
        </div>
            <div id='tem-yatay'>
           <Link onClick={() => setActive("Elevator")  } className='tem-urun' to="" spy={true} smooth={true}> 
           <p className='buton'>
             {t('elevator')}
             </p>
         <div id='tem-urun'>
           <img src={elevator} alt="öğütme teknolojileri" />
         </div>
           </Link>
           <Link onClick={() => setActive("Eklus")}  className='tem-urun' to="" spy={true}> 
           <p className='buton'>
             {t('eklus')}
             </p>
         <div id='tem-urun'>
           <img src={eklus} alt="öğütme teknolojileri" />
         </div>
           </Link>
        </div>
           <div id='tem-yatay'>
           <Link onClick={() => setActive("Helezon")} className='tem-urun' to="" spy={true}> 
           <p className='buton'>
             {t('helezon')}
             </p>
         <div id='tem-urun'>
           <img src={helezon} alt="öğütme teknolojileri" />
         </div>
           </Link>
           <Link onClick={() => setActive("Tupvida")} className='tem-urun' to="" spy={true}> 
           <p className='buton'>
             {t('tupvida')}
             </p>
         <div id='tem-urun'>
           <img src={tupvida} alt="öğütme teknolojileri" />
         </div>
           </Link>
        
         
                 
        </div>
        <div id='tem-yatay'>
           <Link onClick={() => setActive("SiloBosaltici")}  className='tem-urun' to="" spy={true}> 
           <p className='buton'>
             {t('silobosaltici')}
             </p>
         <div id='tem-urun'>
           <img src={silobosaltici} alt="öğütme teknolojileri" />
         </div>
           </Link>
           <Link onClick={() => setActive("Dağıtıcı")}  className='tem-urun' to="" spy={true}> 
           <p className='buton'>
             {t('dagitici')}
             </p>
         <div id='tem-urun'>
           <img src={dagitici} alt="öğütme teknolojileri" />
         </div>
           </Link>
        
         
                 
        </div>
        <div id='tem-yatay'>
           <Link onClick={() => setActive("Kontrol Eleği")} className='tem-urun' to="" spy={true}> 
           <p className='buton'>
             {t('kontrol')}
             </p>
         <div id='tem-urun'>
           <img src={kontrol} alt="öğütme teknolojileri" />
         </div>
           </Link>
           <Link onClick={() => setActive("Klape")}  className='tem-urun' to="" spy={true}> 
           <p className='buton'>
             {t('klape')}
             </p>
         <div id='tem-urun'>
           <img src={klape} alt="öğütme teknolojileri" />
         </div>
           </Link>
        
         
                 
        </div>
        <div id='tem-yatay'>
           <Link onClick={() => setActive("Konveyor") } className='tem-urun' to="" spy={true} smooth={true}> 
           <p className='buton'>
             {t('konveyor')}
             </p>
         <div id='tem-urun'>
           <img src={konveyor} alt="öğütme teknolojileri" />
         </div>
           </Link>
           <Link onClick={() => setActive("Un Silosu")}  className='tem-urun' to="" spy={true}> 
           <p className='buton'>
             {t('silo')}
             </p>
         <div id='tem-urun'>
           <img  src={silo} alt="öğütme teknolojileri" />
         </div>
           </Link>
        
         
                 
        </div>
    </section>

 </div> */
  )
}

export default Tasima